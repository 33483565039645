// 时间处理将毫秒转换成HH:MM:SS 000
export const timeInfo = (time: number) => {
    // const millisecond=time % (1000)
    // const seconds=(time-millisecond)/1000 % 60
    // const minutes=Math.round((time - 30) / (60*1000)) % 60
    // const hours= Math.round((time - 30 * 60*1000) / (60 * 60 * 1000))
    // const hstr=hours<10?('0'+hours):hours
    // const mstr=minutes<10?('0'+minutes):minutes
    // const sstr=seconds<10?('0'+seconds):seconds
    // return hstr +':'+ mstr+':'+ sstr+'  '+millisecond
    const millisecond = time % (1000)
    // 将毫秒转换成秒
    const seconds = Math.floor(time / 1000);

    // 获取小时数
    const hours = Math.floor(seconds / 3600);
    // 获取剩余的分钟数
    const minutes = Math.floor((seconds % 3600) / 60);
    // 获取剩余的秒数
    const secs = seconds % 60;

    // 保证每个部分都为两位数
    const hourstr = hours < 10 ? '0' + hours : hours;
    const minutestr = minutes < 10 ? '0' + minutes : minutes;
    const  secstr = secs < 10 ? '0' + secs : secs;

    // 拼接成HH:MM:SS格式
    return hourstr + ':' + minutestr + ':' + secstr + '  ' + millisecond;
}
// 时间处理将秒转换成HH:MM:SS
export const timeInfominute = (time: number) => {
    // console.log(time)
    if (time < 100) {
        time = 0
    } else {
        time = Math.ceil(time / 1000)
    }
    const seconds = time % 60
    const minutes = Math.round((time - 30) / 60) % 60
    const hours = Math.round((time - 30 * 60) / (60 * 60))
    const hstr = hours < 10 ? ('0' + hours) : hours
    const mstr = minutes < 10 ? ('0' + minutes) : minutes
    const sstr = seconds < 10 ? ('0' + seconds) : seconds
    return hstr + ':' + mstr + ':' + sstr
}
// 时间戳转换时间

export const timedata = (timestamps: number) => {

    const date = new Date(timestamps);
    const Year = date.getFullYear();
    const Moth = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    const Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
    const Hour = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
    const Minute = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
    const Sechond = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    const GMT = Year + '-' + Moth + '-' + Day + '   ' + Hour + ':' + Minute + ':' + Sechond;

    return GMT
}

export const timedataOther = (timestamps: number) => {

    const date = new Date(timestamps);
    const Year = date.getFullYear();
    const Moth = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    const Day = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
    const Hour = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
    const Minute = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
    const Sechond = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    const GMT = Year + '' + Moth + '' + Day + '' + Hour + '' + Minute + '' + Sechond;

    return GMT
}

export const formatSeconds=(seconds:number)=> {
    const days = Math.floor(seconds / 86400);
    let remainder = seconds % 86400;
    const hours = Math.floor(remainder / 3600);
    remainder %= 3600;
    const minutes = Math.floor(remainder / 60);
    const secs = remainder % 60;

    const parts = [];
    if (days > 0) parts.push(`${days}天`);
    if (hours > 0) parts.push(`${hours}小时`);
    if (minutes > 0) parts.push(`${minutes}分`);
    if (secs > 0) parts.push(`${secs}秒`);

    return parts.length === 0 ? '0秒' : parts.join('');
}